.main_div{
    /* display: flex; */
    gap: 4%;
    padding: 20px 50px;
}
.feat_top{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* padding: 0 90px; */
}
.d1{
    display: flex;
justify-content: space-between;
    width: 100%;
    column-gap: 7%;
}
.d2 {
    display: flex;
    gap: 4%;
    margin-bottom: 30px;
    width: 50%;
}
.d2 > img {
    height: 50px;
    width: 50px;
    background: #DAEEFF;
    padding: 10px;
    border-radius: 50%;
}
.d2 > div {
    width: 85%;
}

.d2 > div > h1 {
    margin: 0;
    text-align: start;
    font-size: 20px;
    font-weight: 600;
    font-family: "Inter", sans-serif;
}
.d2 > div > p {
    margin: 10px 0;
    text-align: start;
    color: #5E6075;
    font-family: "Quicksand", sans-serif;
    font-weight: 500;
    font-size:16px;
}

.feat{
    display: flex;
    gap: 2%;
    /* margin-top: 60px; */
    /* padding: 20px 80px; */
}
.feat > img {
 width: 18%;
    /* padding: 20px 80px; */
}
.btm_section{
    margin-top: 100px;
    margin-bottom: 50px;
}

.btm_section h5{
    font-size: 30px;
       font-family: "Lato", sans-serif;
    font-weight: 700;
    font-style: italic;
}
.btm_section p{
    font-size: 18px;
    /* font-style: italic; */
    font-weight: 500;
    margin: 15px 0 0 0;
    font-family: "Montserrat", sans-serif;

}
.btm_section h6{
    font-size: 18px;
    /* font-style: italic; */
    font-weight: 500;
    margin: 0;
    font-family: "Helvetica Neue Medium Extended";
}
.mob{
    display: none;
}

@media screen and (max-width : 1000px) {
   .feat{ padding: 20px;
    margin-top: 0;
gap: 0;
justify-content: space-between;}
.feat { 
    display: none;
}
.mob{
    display: block;
}
}
@media screen and (max-width : 620px) {
    .feat { 
        display: none;
    }
    .mob{
        display: block;
        margin-bottom: 50px;
    }
    .imge{
        width: 85%;
    }
    .d1 {
        width: 100%;
        margin: 0;
        padding: 0;
    }
    .d2 {
        /* gap: 9%; */
        flex-direction: column;
        align-items: center;
        row-gap: 20px;
        text-align: center;
    }
    .main_div{
        padding: 0;
        gap: 0;
        flex-direction: column;

    }
    .btm_section {
        margin-top: 0;
        margin-bottom: 50px;
    padding: 0;
    }
    .btm_section h5 {
        font-size: 25px;
    }
    .btm_section h6{
        font-size: 16px;
    }
    .btm_section p{
        font-size: 16px;
    }
    .feat_top{
        /* padding: 30px; */
    }
.d1 div {
    width: 100%;
    text-align: center;
}
 .d2 >div > h1 {
    width: 100%;
    text-align: center;
    font-size: 20px;
}
.d2 > div >  p {
    /* width: 100%; */
    text-align: center;
}
.d1{
    flex-direction: column !important;
}
.div_head h4{
    margin-bottom: 50px;
}
}