.card1>button {
    background: white;
    color: black;
    border: 1px solid #d9d9d9;
    padding: 10px 20px;
    border-radius: 20px;
    width: fit-content;
    font-family: "Inter", sans-serif;
    font-weight: 600;
}

.card1>img {
    max-width: 250px;
    height: 200px;
    margin-bottom: 70px;
}

.card1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(180deg, #FCB48C 0%, #FFEADF 100%);
    padding: 20px;
    border-radius: 5px;
    /* width: 250px; */
    width: 100%;
}


.div2 {
    display: flex;
    justify-content: space-between;
}

.div2>p {
    margin: 0;
    font-family: "Inter", sans-serif;
}

.div2>button {
    border: 1px solid #d9d9d9d9;
    background: white;
    padding: 7px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    font-family: "Inter", sans-serif;
    font-size: 13px;
}

.div2>button>svg {
    fill: hsl(39deg 97.11% 49.53%) !important;
    font-size: 16px;
}

.h6 {
    font-size: 16px;
    text-align: left;
    font-weight: 700;
    margin: 10px 0;
    font-family: "Inter", sans-serif;
    margin-bottom: 5px;
}

.view {
    color: #00398E;
    background: none;
    border: none;
    text-decoration: underline;
    font-size: 20px;
    font-weight: 500;
    margin: 20px 0;
    cursor: pointer;
    font-family: "Quicksand", sans-serif;

}

.p1 {
    background: linear-gradient(180deg, #FCB48C 0%, #FFEADF 100%);

}

.p2 {
    background: linear-gradient(180deg, #6FDBF9 0%, rgba(207, 245, 255, 0.69) 100%);

}

.p3 {
    background: linear-gradient(180deg, #E8EB8C 0%, #FEFFEE 100%);

}

.p4 {
    background: linear-gradient(180deg, #FF9CA9 0%, rgba(255, 181, 191, 0.48) 100%);
}

.allProducts {
    margin-left: 17px;
    overflow: hidden;
    height: 1000px;
    transition: height 5s ease-in-out;
}
.allProducts > div{
    display: flex;
    flex-wrap: wrap;  
    gap: 30px;
}
.productItem{
    width: 290px;
    margin-bottom: 20px;
    /* width: 100%;
    height: 185px; */
}
/* .productContainer {
    overflow: hidden;
    transition: height 15s ease-in-out;
    transition-timing-function: ease-in-out
  } */
  .showAllProducts {
    height: auto; /* Adjust the delay duration as needed */
  }

  .ProductSlider {
    /* your existing styles */
    overflow: hidden;
}

.allProducts {
    transition: max-height 0.5s ease-in-out;
    max-height: 0;
    overflow: hidden;
}

.allProducts.showAllProducts {
    max-height: 2000px; /* Set a high value to ensure it covers the height of all products */
}
.noProducts {
    margin-top: -40px;
    z-index: 100;
    position: absolute;
    text-align: center;
    color: #e80808;
    left: 45%;
    font-weight: 500;
}
.para{
    color: #000000;
    text-align: left;
    font-size: 14px;
    font-family: "Inter", sans-serif;
margin-bottom: 5px;
}
.para2{
    color: #000000;
    font-size: 14px;
    font-family: "Inter", sans-serif;
margin-bottom: 5px;
font-weight: 500;
display: flex;
gap: 10px;
}
@media screen and (max-width : 1000px) {
    .productItem{
        width: 47.5%;
    }
    .allProducts{
        padding: 20px;
        margin-left: 10px;
    }
    .allProducts.showAllProducts {
        max-height: 2350px; /* Set a high value to ensure it covers the height of all products */
    }
}
@media screen and (max-width : 800px) {
    .products_container {
        padding: 20pt 0;
    }

    .productItem{
        width: 47%;
    }
    .allProducts{
        padding: 20px;
        margin-left: 10px;
    }
}
@media screen and (max-width : 600px) {
   .div_head h4{
    margin-bottom: 50px;
   }
   .productItem{
    width: 100%;
}
.allProducts{
    margin-left: 0;
}
.allProducts.showAllProducts {
    max-height: max-content; /* Set a high value to ensure it covers the height of all products */
}
}