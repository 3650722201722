.prod{
    width: 45%;
}
.main_div{
    display: flex;
}
.card{
    background: linear-gradient(180deg, #FCB48C 0%, #FFEADF 100%);
border-radius: 5px;
height: 100%;
}
.card1 {
    /* height: 85.5vh; */
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.card1 > img {
    width: 300px;
    max-height: 300px;
}
.head{
    font-size: 25px;
    text-align: left;
    font-weight: 700;
}
.description {
    text-align: left;
    font-size: 10pt;
    font-weight: 400;
}
.para{
    text-align: left;
    font-weight: 700;
    font-size: 16px;
    margin: 15px 0;
}

.p2{
    margin-bottom: 5px !important;
}
.main_div2{
    display: flex;
    gap: 25px;
}
.main_div3{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.main_div3 > div {
    width: 40px;
    height: 40px;
    padding: 10px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F7F7F7;
}
.img1{
    max-width: 30px;
    max-height: 30px;
}
.main_div3 > span {
    font-size: 12px;
    font-weight: 500;
    margin: 5px 0;
}
.ul {
    list-style: none;
    padding: 0;
    /* display: flex; */
    justify-content: space-between;
}
.inn_ul{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: start;
    gap: 2%;
    /* justify-content: left; */
}
.inn_ul p {
    font-size: 13px;
    font-weight: 600;
    text-align: left;
    width: 40%;
    margin: 4px 0;
}
.inn_ul span {
        font-size: 13px;
        font-weight: 500;
        text-align: left;
        width: 60%;
        margin: 4px 0;
        word-wrap: break-word;
    } 

.btm_btn_div{
    width: 100%;
    display: flex;
    gap: 2%;
}
.p9{
    color: #000000;
    font-weight: 400;
    font-size: 17px;
    margin-bottom: 0;
}
.head2{
    font-size: 12px;
    color: #00AC45;
    font-weight: 400;
    text-align: left;
    margin: 5px 0 10px 0;
}
.para2{
    text-align: left;
    font-size: 20px;
    font-weight: 700;
    display: flex;
    gap: 10px;
    margin-bottom: 0;
    align-items: center;
}
.para2 > span{
    /* color: #0E9243; */
    /* background: #EAFFF3; */
    /* padding: 5px 10px; */
    font-size: 14px;
    /* height: 30px; */
    border-radius: 5px;
    font-weight: 500;
}
.buy_btn_container {
    width: 100%;
}
.buy_btn{
    background: linear-gradient(30.09deg, #339EDA 12.71%, #B2E9FF 87.13%);
    width: 100%;
    border: none;
    border-radius: 5px;
    margin: 15px 0px 0 0;
    padding: 10px;
    color: #fff;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    height: 45px;
}

.bdy{
    /* padding: 10px 30px 30px 12px !important; */
    padding: 10px 20px 20px 0px !important;

}
.head_modal{
    font-size: 18px;
    font-weight: 600;
    padding: 10px 30px 0px 30px !important;
    border: none !important ;
}
.main_div2 > div{
    width: 500px;
    display: flex;
    gap: 30px;
    overflow-x: scroll;
}
.main_div3 > p{
    /* width: max-content; */
    font-size: 12px;
    margin-top: 5px;
    font-weight: 500;
}
/* .main_div2 > div::-webkit-scrollbar {
    width: 10px;
    height: 5px;
  } */
  
  /* Track */
  /* .main_div2 > div::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  } */
   
  /* Handle */
  /* .main_div2 > div::-webkit-scrollbar-thumb {
    background: #989898; 
    border-radius: 10px;
  } */
  
  /* Handle on hover */
  /* .main_div2 > div::-webkit-scrollbar-thumb:hover {
    background: #989898; 
  } */
@media screen and (max-width:1000px) {
    .main_div2 > div{
        /* width: 267px; */
    }
    .btm_btn_div{
        flex-direction: column;
    }
    .btm_btn_div{
        flex-direction: column;
    }
 .main_div{
    flex-direction: column;
 }
 .main_div2{
    gap: 20px;
 }
 .card1{
    height: 400px !important;
 }
 .prod{
    width: 100%;
    margin-bottom: 20px;
 }
 .bdy{
    padding: 20px !important;
}
.head{
    text-align: center;
}
.home_slider2 .swiper-pagination-bullet-active {
height: 10px !important;
width: 10px !important;
}
.buy_btn{
    margin-bottom: 0;
}
    
}
@media screen and (max-width:620px) {
    .btm_btn_div{
        flex-direction: column;
    }
 .main_div{
    flex-direction: column;
 }
 .main_div2{
    gap: 20px;
 }
 .card1{
    height: 400px !important;
 }
 .prod{
    width: 100%;
    margin-bottom: 20px;
 }
 .bdy{
    padding: 20px !important;
}
.head{
    text-align: left;
}
.home_slider2 .swiper-pagination-bullet-active {
height: 10px !important;
width: 10px !important;
}
.buy_btn{
    margin-bottom: 0;
}
.para{
   
    margin: 25px 0;
}
.p2{
    margin-bottom: 20px !important;
}
}