.main_div {
    padding: 90px 140px;
    background: #F7FBFF;
    position: relative;
}

.mob_div {
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: relative;
}

/* .mob_div > img{height: 550px;
    width: 425px;
    } */
.mob_div>div>h4 {
    font-size: 40px;
    margin: 0;
    text-align: left;

        font-family: "Outfit", sans-serif;
        font-optical-sizing: auto;
        font-weight: 600;
        font-style: normal;
      
}

.mob_div>div>p {
    font-size: 18px;
    color: #5E6075;
    text-align: left;
    margin: 20px 0;
    font-family: "Outfit", sans-serif;
        font-optical-sizing: auto;
        font-weight: 400;
        font-style: normal;
    /* font-weight: 500; */
}

.mob_div>div {
    width: 50%;
}

.mob2 {
    width: 50%;
    margin-top: -80px !important;
}

/* .img_mob1 {
        position: absolute;
        left: 11%;
        top: 16%;
        width: 765px;
    } */
.mob {
    position: relative;
    z-index: 4;
    height: 550px;
    object-fit: contain;
}

.vector1 {
    /* position: absolute;
    z-index: 1;
    width: 791px;
    top: 63%;
    left: 134px; */
    
        position: absolute;
    /* bottom: -198px; */
    z-index: 1;
    width: 685px;
    top: 63%;
    left: 134px;
}

.vector2 {
   
    /* position: absolute;
    bottom: -180px;
    z-index: 1;
    width: 938px;
    left: 58px;
    top: 48%; */
    position: absolute;
    bottom: -180px;
    z-index: 0;
    width: 1020px;
    left: 36px;
    top: 55%;
}

@media screen and (max-width : 1000px) {
    .vector1 {
        position: absolute;
        /* bottom: -198px; */
        z-index: 1;
        width: 395px;
        top: 63%;
        left: 69px;
    }

    .vector2 {
        position: absolute;
        bottom: -148px;
        z-index: 1;
        width: 575px;
        left: -14px;
        top: 68%;
    }
}


/* @media screen and (min-width : 1600px) {
    .vector1 {
        position: absolute;
        z-index: 1;
        width: 1139px;
        top: 36%;
        left: 167px;
    }
    .vector2 {
        position: absolute;
        bottom: -180px;
        z-index: 1;
        width: 1346px;
        left: 96px;
        top: 32%;}
} */
@media screen and (min-width: 2200px) and (max-width: 2500px) {
    .vector1 {
        position: absolute;
        /* bottom: -198px; */
        z-index: 1;
        width: 1300px;
        top: 20%;
        left: 250px;

    }
    .vector2 {
        position: absolute;
        bottom: -180px;
        z-index: 1;
        width: 1500px;
        left: 110px;
        top: 30%;}
}
@media screen and (min-width: 2000px) and (max-width: 2200px) {
    .vector1 {
        position: absolute;
        /* bottom: -198px; */
        z-index: 1;
        width: 1200px;
        top: 36%;
        left: 167px;

    }
    .vector2 {
        position: absolute;
        bottom: -180px;
        z-index: 1;
        width: 1400px;
        left: 110px;
        top: 30%;}
}
@media screen and (min-width: 1800px) and (max-width: 2000px) {
    .vector1 {
        position: absolute;
        /* bottom: -198px; */
        z-index: 1;
        width: 1100px;
        top: 36%;
        left: 167px;

    }
    .vector2 {
        position: absolute;
        bottom: -180px;
        z-index: 1;
        width: 1300px;
        left: 50px;
        top: 44%;}
}
@media screen and (min-width: 1560px) and (max-width: 1800px) {

    .vector1 {
        position: absolute;
        /* bottom: -198px; */
        z-index: 1;
        width: 877px;
        top: 36%;
        left: 167px;

    }
    .vector2 {
        position: absolute;
        bottom: -180px;
        z-index: 1;
        width: 1179px;
        left: 50px;
        top: 44%;}
}
@media screen and (max-width : 1150px) {
.vector1{
    width: 597px;
    top: 63%;
    left: 60px;
}
.vector2{
    width: 693px;
    left: 30px;
    top: 55%;
}
}
@media screen and (max-width : 880px) {
    .vector1 {
        display: none;
    }

    .vector2 {
        display: none;
    }
    .main_div{
        padding: 30px;
    }
    .mob_div{
        justify-content: space-between;
    }
}
@media screen and (max-width : 620px) {
    .mob_div {
        position: relative;
        flex-direction: column;
        /* margin-bottom: 80px; */
    }

    .mob_div:nth-child(2) {
        margin: 80px 0;
        position: relative;
        flex-direction: column-reverse;
    }

    .mob {
        height: 400px;
    }

    .vector1 {
        display: none;
    }

    .vector2 {
        display: none;
    }

    .mob {
        /* display: none; */
    }

    .main_div {
        padding: 30px;
    }

    .mob_div>div {
        width: 100%;
        margin-top: -30px !important;
        text-align: center;
    }

    .mob_div>div>p {
        margin: 10px 0;
        text-align: center;
    }

    .mob_div>div>h4 {
        font-size: 25px;
        text-align: center;
    }
}