.head{
    margin: 100px 0 50px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.head h4{
    font-size: 35px;
    font-weight: 500;
    margin: 0;
    margin-bottom: 10px;
    font-family: "Helvetica Neue Medium Extended";
    width: 65%;
}
.head p{
    margin: 0;
    font-size: 18px;
    color: #737373;
}

@media screen and (max-width : 620px) {
    .head{
        margin: 35px 0 35px  0;
    }
    .head h4{
        font-size: 25px;
        width: 100%;
    }
 }